.home-page .logo-image {
    height: 5rem;
    text-align: center;
    margin: 0;
}

.home-page .slogan {
    font-family: 'Didot', serif;
    color: #FFF;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.home-page .play-button {
    transform: scale(0.8);
}

@media (max-width: 600px) {
    .home-page .logo-image {
        height: 2rem;
    }

    .home-page .slogan {
        font-size: 1rem;
        padding-bottom: 0;
    }
}

@media (max-width: 992px) {
    .home-page .play-button {
        transform: scale(0.6);
    }
}