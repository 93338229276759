@font-face {
  font-family:"Didot";
  src: url("../../assets/Didot.ttf");
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Didot', serif;
  font-weight: normal;
}

i, a {
  font-family: 'Didot', serif;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
}