.project-video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-video-list > * {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
}