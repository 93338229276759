.credits-container {
    position: relative;
}

.credits-preview {
    max-height: 20rem;
    overflow-y: scroll;
}

.credits-preview div:first-child p {
    margin-top: 0;
}

.credits-full {
    position: absolute;
    z-index: 10;
    top: -1rem;
    background: #FFF;
}

.still {
    max-width: 100%;
    padding: 0.8rem 0;
}