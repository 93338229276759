body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.logo-image {
    height: 1.2rem;
}
.video-player-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
}

.video-player {
    background: #FFF;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.video-player .play-button {
    width: 0;
    height: 0;
    border-top: 1.8rem solid transparent;
    border-bottom: 1.8rem solid transparent;
    border-left: 3rem solid #d8eaeb;
    transition: opacity .3s;
}

.video-player .play-button:hover {
    opacity: 0.5;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit, .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.fade-exit {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
}

.fade-exit-active {
    opacity: 0.01;
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    transition: opacity 300ms ease-in, -webkit-transform 300ms;
    transition: opacity 300ms ease-in, transform 300ms;
    transition: opacity 300ms ease-in, transform 300ms, -webkit-transform 300ms;
}
.home-page .logo-image {
    height: 5rem;
    text-align: center;
    margin: 0;
}

.home-page .slogan {
    font-family: 'Didot', serif;
    color: #FFF;
    font-size: 1.5rem;
    padding-bottom: 1rem;
    text-align: center;
}

.home-page .play-button {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
}

@media (max-width: 600px) {
    .home-page .logo-image {
        height: 2rem;
    }

    .home-page .slogan {
        font-size: 1rem;
        padding-bottom: 0;
    }
}

@media (max-width: 992px) {
    .home-page .play-button {
        -webkit-transform: scale(0.6);
                transform: scale(0.6);
    }
}
.work-list-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work-list-row {
    flex-grow: 1;
    display: block;
    text-decoration: none;
    color: inherit;
}

.work-list-row figure {
    position: relative;
    margin: 0.8rem 0;
    text-align: center;
    overflow: hidden;
}

.work-list-row:hover img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.work-list-row img {
    max-width: 100%;
    max-height: 33.5rem;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.work-list-row figcaption {
    position: absolute;
    padding: 0 5%;
    height: 100%;
    width: 90%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 0.5s;
}

.work-list-row:hover figcaption {
    opacity: 1;
}
.clients-list {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}

.clients-list img {
    max-height: 2rem;
    margin: 1rem 2rem;
}

.page-about .about-team {
    width: 100%;
}

@media (max-width: 600px) {
    .clients-list img {
        max-height: 1.5rem;
        margin: 0.5rem 0.5rem;
    }
}
.page-connect {
    text-align: center;
}

.page-connect img {
    width: 100%;
}
.credits-container {
    position: relative;
}

.credits-preview {
    max-height: 20rem;
    overflow-y: scroll;
}

.credits-preview div:first-child p {
    margin-top: 0;
}

.credits-full {
    position: absolute;
    z-index: 10;
    top: -1rem;
    background: #FFF;
}

.still {
    max-width: 100%;
    padding: 0.8rem 0;
}
.project-video-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.project-video-list > * {
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
}
.work-list-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work-list-row {
    flex-grow: 1;
    display: block;
    text-decoration: none;
    color: inherit;
}

.work-list-row figure {
    position: relative;
    margin: 0.8rem 0;
    text-align: center;
    overflow: hidden;
}

.work-list-row:hover img {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.work-list-row img {
    max-width: 100%;
    max-height: 33.5rem;
    transition: -webkit-transform 0.3s;
    transition: transform 0.3s;
    transition: transform 0.3s, -webkit-transform 0.3s;
}

.work-list-row figcaption {
    position: absolute;
    padding: 0 5%;
    height: 100%;
    width: 90%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 0.5s;
}

.work-list-row:hover figcaption {
    opacity: 1;
}
.header-spacer {
    height: 54px;
}

.header-navigation {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #FFF;
    z-index: 1000;
}

.header-navigation ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.header-navigation li {
    padding: 0 1rem;
}

.header-navigation ul a {
    color: inherit;
    text-decoration: none;
    padding-bottom: 0.2rem;
    border-bottom: 2px solid #FFF;
    transition: border-color 0.3s;
}

.header-navigation li a:hover {
    border-bottom: 2px solid #556270;
}

.header-navigation li a.header-navigation-selected {
    border-bottom: 2px solid #556270;
}

@media (max-width: 600px) {
    .header-navigation li {
        padding: 0 0.5rem;
    }
}
.footer {
    border-bottom: 0.4rem solid #556270;
}

.footer .social-media {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
}

.footer .social-media-icon {
    width: 1rem;
    padding: 0 0.5rem;
}
@font-face {
  font-family:"Didot";
  src: url(/static/media/Didot.5415e640.ttf);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Didot', serif;
  font-weight: normal;
}

i, a {
  font-family: 'Didot', serif;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: normal;
}
