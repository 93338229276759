.header-spacer {
    height: 54px;
}

.header-navigation {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px;
    width: 100%;
    top: 0;
    left: 0;
    box-sizing: border-box;
    background-color: #FFF;
    z-index: 1000;
}

.header-navigation ul {
    display: flex;
    list-style-type: none;
    padding: 0;
}

.header-navigation li {
    padding: 0 1rem;
}

.header-navigation ul a {
    color: inherit;
    text-decoration: none;
    padding-bottom: 0.2rem;
    border-bottom: 2px solid #FFF;
    transition: border-color 0.3s;
}

.header-navigation li a:hover {
    border-bottom: 2px solid #556270;
}

.header-navigation li a.header-navigation-selected {
    border-bottom: 2px solid #556270;
}

@media (max-width: 600px) {
    .header-navigation li {
        padding: 0 0.5rem;
    }
}