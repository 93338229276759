.clients-list {
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
}

.clients-list img {
    max-height: 2rem;
    margin: 1rem 2rem;
}

.page-about .about-team {
    width: 100%;
}

@media (max-width: 600px) {
    .clients-list img {
        max-height: 1.5rem;
        margin: 0.5rem 0.5rem;
    }
}