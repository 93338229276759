.work-list-component {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.work-list-row {
    flex-grow: 1;
    display: block;
    text-decoration: none;
    color: inherit;
}

.work-list-row figure {
    position: relative;
    margin: 0.8rem 0;
    text-align: center;
    overflow: hidden;
}

.work-list-row:hover img {
    transform: scale(1.05);
}

.work-list-row img {
    max-width: 100%;
    max-height: 33.5rem;
    transition: transform 0.3s;
}

.work-list-row figcaption {
    position: absolute;
    padding: 0 5%;
    height: 100%;
    width: 90%;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;

    transition: opacity 0.5s;
}

.work-list-row:hover figcaption {
    opacity: 1;
}