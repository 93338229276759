.footer {
    border-bottom: 0.4rem solid #556270;
}

.footer .social-media {
    display: flex;
    list-style-type: none;
    justify-content: flex-end;
}

.footer .social-media-icon {
    width: 1rem;
    padding: 0 0.5rem;
}