.video-player-container {
    position: relative;
    overflow: hidden;
    margin-bottom: 2rem;
}

.video-player {
    background: #FFF;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
}

.video-player .play-button {
    width: 0;
    height: 0;
    border-top: 1.8rem solid transparent;
    border-bottom: 1.8rem solid transparent;
    border-left: 3rem solid #d8eaeb;
    transition: opacity .3s;
}

.video-player .play-button:hover {
    opacity: 0.5;
}

.fade-enter {
    opacity: 0.01;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit, .fade-exit-active {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.fade-exit {
    opacity: 1;
    transform: scale(1);
}

.fade-exit-active {
    opacity: 0.01;
    transform: scale(1.02);
    transition: opacity 300ms ease-in, transform 300ms;
}